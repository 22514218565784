

































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import CleanTime from '@/models/CleanTime';
import {WEEKDAYS} from '@/Constants';
import {Permission} from '@/misc/enums/permission.enum';

const UserStore = namespace('user');

/**
 * Simple table component that lists all users in a table
 */
@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    ActionMenuComponent: () => import(
      '@/components/shared/table/ActionMenu.component.vue'),
    UserInitialsComponent: () => import(
      /* webpackChunkName: "UserInitialsComponent" */
      '@/components/user/UserInitials.component.vue'),
    UserManageComponent: () => import(
      /* webpackChunkName: "UserManageComponent" */
      '@/components/user/UserManage.component.vue'),
    TableComponent: () => import(
      '@/components/shared/table/Table.component.vue'),
  },
})
export default class UserTableComponent extends Vue {

  @UserStore.Action('editUserAction')
  private editUserAction!: (user: User) => Promise<User>;
  @UserStore.Mutation('storeActiveUser')
  private activeUserMutation!: (user: User) => any;
  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: { companyId: string, populate?: string[] }) => Promise<User[]>;
  @UserStore.Action('loadUserAction')
  private loadUserAction!: (companyId: string) => Promise<User>;
  @UserStore.Action('deleteUserAction')
  public deleteUserAction!: (user: User) => Promise<User>;
  @UserStore.Action('setUserStatusAction')
  private setUserStatusAction!: (payload: {
    id: string,
    active: boolean,
    preview?: boolean,
    force?: boolean,
  }) => Promise<User>;

  private showDeleteUserDialog: boolean = false;
  private showChangeStatusUserDialog: boolean = false;

  private showUserDeactivationDialog: boolean = false;
  private selectedUser?: User;
  @Prop({default: []})
  public users!: User[];

  @Prop({default: ''})
  private searchString: string = '';

  private userConfig: UserInitialsConfig = {
    showFullName: false,
    showTooltip: false,
    big: true,
  };

  constructor() {
    super();
  }

  get isActive() {
    const inactive: string = (this.$t('GENERAL.INACTIVE') as string).toLowerCase();
    const active: string = (this.$t('GENERAL.ACTIVE') as string).toLowerCase();

    return {
      opposite: this.selectedUser!.active ? inactive : active,
    };
  }

  get tableHeaders(): any[] {
    return [
      {text: this.$t('GENERAL.NAME').toString(), value: 'name', width: '30%'}, // -5
      {text: this.$t('GENERAL.ROLE').toString(), value: 'role', width: '20%'}, // -5
      // TODO reimplement Working Times
      // {text: this.$t('GENERAL.WORKING_TIMES').toString(), value: 'availableAtCleanTimes', width: '15%'},
      {text: this.$t('GENERAL.PHONE').toString(), value: 'phone', width: '14%'},
      {text: this.$t('GENERAL.EMAIL').toString(), value: 'email', width: '22%'}, // -5
      {text: this.$t('GENERAL.STATUS').toString(), value: 'active', width: '9%', class: 'justify-center'},
      {text: '', value: 'actions', width: '5%', class: 'justify-start'},
    ];
  }

  public onRowClicked(user: User) {
    this.$router.push({
      name: 'userDetails', params: {
        companyId: this.$route.params.companyId,
        userId: user.id!,
      },
    });
  }

  private getColor(color: string): string {
    return this.$colorHandler.getThemeColor(color);
  }

  /**
   * User delete functions
   */
  public onUserDeleteClick(user: User) {
    this.showDeleteUserDialog = true;
    this.selectedUser = user;
  }

  public async onUserDelete(user: User) {
    this.showDeleteUserDialog = false;
    try {
      await this.deleteUserAction(user);
      this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DELETE.SUCCESS');
      await this.loadUsersAction({companyId: this.$route.params.companyId});
    } catch (e) {
      this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_DELETE.ERROR');
    }
  }

  public async onUserEditClick(user: User) {
    this.selectedUser = await this.loadUserAction(user.id!);
    this.$emit('editUser', this.selectedUser);
  }

  /**
   * Methods for deactivating Employees
   */
  public onUserStatusToggleClick(event: Event, updatedUser: User) {
    if (this.$userRoleHandler.hasPermission(Permission.USER_UPDATE) && !this.$userRoleHandler.isLoggedInUser(updatedUser.id!)) {
      this.showChangeStatusUserDialog = true;
      this.selectedUser = updatedUser;
      event.stopPropagation();
    }
  }

  public onUserStatusChange(updatedUser: User) {
    this.showChangeStatusUserDialog = false;
    if (updatedUser.active === true) {
      this.showUserDeactivationDialog = true;
    } else {
      this.changeUserStatus(updatedUser);
    }
  }

  public async changeUserStatus(updatedUser: User) {
    this.showUserDeactivationDialog = false;
    const params = {
      id: updatedUser.id!,
      active: !updatedUser.active!,
      force: true,
    };
    try {
      await this.setUserStatusAction(params);

      updatedUser.active ?
        this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DEACTIVATE') :
        this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_ACTIVATE');
    } catch (error) {
      // Reset Activate State to False, because some error happened
      updatedUser.active = false;

      // Show Correct error Message
      if (error.status === 402) {
        this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.QUOTA_EXCEEDED');
      } else {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    } finally {
      await this.loadUsersAction({companyId: this.$route.params.companyId});
    }
  }

  /**
   * Returns a string representation of the byWeekArray found in the cleanTime
   *
   * TODO dublicate code
   */
  public getCleanTimeFrequency(cleanTimes: CleanTime[]): string {
    const days: string[] = [];

    for (const cleanTime of cleanTimes) {
      // Check if all days were selected
      if (cleanTime.byWeekDay === null) {
        cleanTime.byWeekDay = WEEKDAYS.map((val: string) => val);
      }

      // If every day return with appropiate message

      // go through all days in our clean Time and create the correct day message
      for (const byWeekday of cleanTime.byWeekDay) {

        // Find matches with regex, one for just the characters and one for just the numbers
        const regDay = byWeekday!.match(/[a-z]+/)![0];

        // if no recurrence in day string just add the day
        // add day
        const translatedDay = this.$t(`GENERAL.DAYS_SHORT.${regDay.toUpperCase()}`).toString();
        if (!days.includes(translatedDay)) {
          days.push(translatedDay);
        }
      }
    }

    if (days.length > 7) {
      return this.$t(`CLEANTIME_OVERVIEW.FREQUENCY.EVERY_DAY`).toString();
    }

    return days.join(',');
  }
}
